.container {
  flex-wrap: wrap;
  padding: 0.375rem;
  gap: 0.375rem;
  min-height: 2.625rem;
}

.chip {
  flex-wrap: nowrap;
  height: fit-content;
}
