// Redondeado
.circle-radius {
  border-radius: 50%;
}

.object-contain {
  object-fit: contain;
}

.grayscale {
  filter: grayscale(100%);
}

.s-h-fit {
  height: fit-content;
}

.s-ml-auto {
  margin-left: auto;
}

.s-border-none {
  border: none;
}

.s-h-40px {
  height: 40px;
}

.s-rounded-4px {
  border-radius: 4px;
}

/* ----- Border radius with css variables ----- */
@include variables-class($radius, border-radius);

@include prototype('to-bottom', margin-top, auto);
