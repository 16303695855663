.container {
  flex-wrap: wrap;
  padding: 0.375rem;
  gap: 0.375rem;
}

.chip {
  flex-wrap: nowrap;
  height: fit-content;
}

.input {
  padding-block: 0.25rem !important;
  padding-inline: 0.75rem !important;
  border: none;
  border-radius: 0.25rem;
  min-width: 13.75rem;
  flex: 1;
}
